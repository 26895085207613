import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private http: HttpClient) { }

  public createCharge(currency, amount, source, description, email, idConcurso, ipc, idBinomio, fase): Observable<any>{
    const params: string = JSON.stringify({ currency, amount, source, description, email, idConcurso, ipc, idBinomio, fase });
    //Al crear un cliente no hay usuario, pero siempre debe ser con la llaves nuevas
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': '7cc263a1-a490-4337-8302-151490495e56'
    };
    
    return this.http.post(`https://api.equestrian.digital/stripe/create_charge.php`, params,  { headers });
  }

  public releaseVideo(link, idConcurso, ipc, idBinomio, fase, release, email): Observable<any>{
    const params: string = JSON.stringify({ link, idConcurso, ipc, idBinomio, fase, release, email });
    //Al crear un cliente no hay usuario, pero siempre debe ser con la llaves nuevas
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': '7cc263a1-a490-4337-8302-151490495e56'
    };
    
    return this.http.post(`https://api.equestrian.digital/videos/release-video-purchased/`, params,  { headers });
  }

  public updateResults(ipc): Observable<any>{
    return this.http.get(`https://api.equestrian.digital/actualizar/resultados/pruebas.php?id=${ipc}`, { });
  }

  public createPaymentIntent(amount, currency, idConcurso, email = '', metadata = {}, customer = '', paymentMethod = '', sr = false, description = ''): Observable<any>{
    const params = JSON.stringify({ amount, currency, idConcurso, email, metadata, customer, paymentMethod, sr, description });
    return this.http.post('https://api.equestrian.digital/stripe/PaymentIntent/create.php', params);
  }
}
