import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';
import { VideoShowPassComponent } from './video-show-pass/video-show-pass.component';

const routes: Routes = [
  {
    path: ':protocol/:hostname/:bucket/:video',
    component: PaymentComponent,
    pathMatch: 'full'
  },
  {
    path: 'video-show-pass/:show',
    component: VideoShowPassComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
