import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripeService } from '../services/stripe.service';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
declare var $: any;
declare var Stripe: any;

@Component({
  selector: 'app-video-show-pass',
  templateUrl: './video-show-pass.component.html',
  styleUrls: ['./video-show-pass.component.scss']
})
export class VideoShowPassComponent implements OnInit, AfterViewInit {
  private show: string;
  private showInfo: any;
  public showName: String;
  private stripe: any;
  private elements: any;
  public form: FormGroup;
  public paseComprado: boolean;

  constructor(private route: ActivatedRoute, private stripeService: StripeService, private db: AngularFirestore) {
    this.showName = "-";
    this.show = this.route.snapshot.params.show;
    this.stripe = Stripe(environment.stripe.publishableKey);
    this.elements = this.stripe.elements();
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
    this.paseComprado = false;
  }

  ngOnInit() {
    console.log('v1.2');
    
    this.db.collection('concursos').doc('us_' + this.show).get()
    .subscribe(docSnapshot => {
      if (docSnapshot.exists) {
        console.log('Document exists');
        // If the document exists, retrieve its data
        this.showInfo = docSnapshot.data();
        this.showName = this.showInfo.nombre;
        this.createPaymentIntent();
      } else {
        // Handle case when the document does not exist
        console.log('Document does not exist.');
      }
    });
  }

  ngAfterViewInit(): void {
    $.App.init();
  }
  
  private async createPaymentIntent(){
    const metadata = { 
      idConcurso: this.show 
    };
    const { clientSecret, message } = await this.stripeService.createPaymentIntent(200, 'usd', this.show, '', metadata,'','', true, 'Show Pass for ' + this.showName).toPromise();
    if (message) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.elements = this.stripe.elements({ clientSecret, locale: 'en' });
    const paymentElement = this.elements.create("payment");
    paymentElement.mount("#payment-element");
  }

  public async submit(e){
    if(!this.form.valid) return;
    e.preventDefault();
    this.setLoading(true);

    const { paymentIntent, error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:4242/public/checkout.html",//Solo para metodos de pago que requieran redireccionamiento
        payment_method_data: {
          billing_details: {
            address: {
              city: '',
              country: '',
              line1: '',
              line2: '',
              postal_code: '',
              state: ''
            },
            email: this.form.value.email,
            name: '',
            phone: ''
          },
          metadata: {
            show: this.show
          }
        },
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // add leading zero for single digit months
    const day = currentDate.getDate().toString().padStart(2, '0'); // add leading zero for single digit days
    const hours = currentDate.getHours().toString().padStart(2, '0'); // add leading zero for single digit hours
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // add leading zero for single digit minutes
    const seconds = currentDate.getSeconds().toString().padStart(2, '0'); // add leading zero for single digit seconds
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    // redirected to the `return_url`.
    let payment = { 
      email: this.form.value.email,
      date: formattedDateTime,
      paymentIntent: paymentIntent  
    };

    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      this.showMessage(error.message);
      this.setLoading(false);
      return
    } else if (error) {
      this.showMessage("An unexpected error occured.");
      this.setLoading(false);
      return
    }

    //Agregar email a listado de personas con video show pass en firestore
    this.db.collection('concursos').doc(`us_${this.show}`).collection('videoShowPass').add(payment);
    this.paseComprado = true;
    this.setLoading(false);
  }

  // Fetches the payment intent status after payment submission
  public async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------
  public showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }

  // Show a spinner on payment submission
  public setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      const submit: any = document.querySelector("#submit");
      submit.disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
      this.form.disable();
    } else {
      const submit: any = document.querySelector("#submit");
      submit.disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
      this.form.enable();
    }
  }
}
