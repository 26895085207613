import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripeService } from '../services/stripe.service';
declare var $: any;
declare var Stripe: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  private protocol: string;
  private hostname: string;
  private bucket: string;
  private video: string;
  private stripe: any;
  private elements: any;
  private card: any;
  public show: any;
  public competition: any;
  public error: string;
  private tarjetaValida: boolean;
  public form: FormGroup;
  public videoLiberado: boolean;
  public link: string;

  constructor(private route: ActivatedRoute, private stripeService: StripeService, private router: Router) {
    this.protocol = this.route.snapshot.paramMap.get('protocol');
    this.hostname = this.route.snapshot.paramMap.get('hostname');
    this.bucket = this.route.snapshot.paramMap.get('bucket');
    this.video = this.route.snapshot.paramMap.get('video');
    this.show = this.video.split('-')[0];
    this.competition = this.video.split('-')[1];
    this.stripe = Stripe(environment.stripe.publishableKey);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });
    this.error = '';
    this.tarjetaValida = false;
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      release: new FormControl(true, []),
    });
    this.videoLiberado = false
   // this.link = `https://splitrockjumpingtour.com/clip-page/?clip=${this.protocol}//${this.hostname}/${this.bucket}/${this.video.split('&')[0]}`;
   let linkUrl = (`${this.protocol}//${this.hostname}/${this.bucket}/${this.video.split('&')[0]}`);
   let encodedURL = btoa(linkUrl);
   this.link = `https://splitrockjumpingtour.com/clip-viewer/${encodedURL}`;
  }

  ngAfterViewInit(): void {
    $.App.init();
    /*this.card.mount('#card-element');
    this.card.on('change', (e) => this.cardHandler(e));*/
  }

  ngOnDestroy(): void {
    /*this.card.removeEventListener('change', (e) => this.cardHandler(e));
    this.card.destroy();*/
  }

  ngOnInit() {
    console.log('v1.1');
    console.log('Show: ', this.show);
    console.log('Competition: ', this.competition);
    //console.log('Link: ', this.link);
    this.createPaymentIntent();
  }

  /*private cardHandler(event): void {
    if (event.complete) {
      this.error = '';
      this.tarjetaValida = true;
    } else if (event.error) {
      this.error = event.error.message;
      this.tarjetaValida = false;
    } else {
      this.error = '';
      this.tarjetaValida = false;
    }
  }*/

  /*public async submit2(): Promise<void> {
    if (this.tarjetaValida && this.form.valid) {
      this.form.disable();
      //Proceso de pago
      const config = {
        currency: 'usd',
        email: this.form.value.email
      };

      const { token, error } = await this.stripe.createToken(this.card, config);

      if (error) {
        this.error = error.message;
        this.form.enable();
        return;
      }

      const idConcurso = this.video.split('&')[0].split('-')[0];
      const ipc = this.video.split('&')[0].split('-')[1];
      const idBinomio = this.video.split('&')[0].split('-')[2];
      const fase = this.video.split('&')[0].split('-')[3].split('.')[0];
      //const link = `${this.protocol}//${this.hostname}/${this.bucket}/${this.video.split('&')[0]}`;

      this.stripeService.createCharge(config.currency, 1000, token.id, 'Video Clip Purchase', config.email, idConcurso, ipc, idBinomio, fase).subscribe(
        response => {
          if (!response.error) {
            //Enviar mail y o liberar video en app
            this.stripeService.releaseVideo(this.link, idConcurso, ipc, idBinomio, fase, this.form.value.release, config.email).subscribe(
              resp => {
                if (!resp.error) {
                  //Actualizar resultados en app
                  if (this.form.value.release) {
                    this.stripeService.updateResults(ipc).toPromise();
                  }
                  this.videoLiberado = true;
                  this.form.enable();
                } else {
                  $.NotificationApp.send("Error", resp.message, 'bottom-right', '#fa5c7c', 'error');
                  this.form.enable();
                  console.log(resp);
                }
              },
              err => {
                $.NotificationApp.send("Error", 'The video has not been released, please contact us.', 'bottom-right', '#fa5c7c', 'error');
                this.form.enable();
                console.log(err);
              }
            );
          } else {
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            this.form.enable();
            console.log(response);
          }
        },
        error => {
          $.NotificationApp.send("Error", 'The payment has not been processed, please try again.', 'bottom-right', '#fa5c7c', 'error');
          this.form.enable();
          console.log(error);
        }
      );
    }
  }*/

  private async createPaymentIntent(){
    const idConcurso = this.video.split('&')[0].split('-')[0];
    const ipc = this.video.split('&')[0].split('-')[1];
    const idBinomio = this.video.split('&')[0].split('-')[2];
    const fase = this.video.split('&')[0].split('-')[3].split('.')[0];
    const metadata = { idConcurso, ipc, idBinomio, fase };
    const { clientSecret, message } = await this.stripeService.createPaymentIntent(12, 'usd', idConcurso, '', metadata,'','', true).toPromise();
    if (message) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.elements = this.stripe.elements({ clientSecret, locale: 'en' });
    const paymentElement = this.elements.create("payment");
    paymentElement.mount("#payment-element");
  }

  public async submit(e){
    if(!this.form.valid) return;
    e.preventDefault();
    this.setLoading(true);
    const idConcurso = this.video.split('&')[0].split('-')[0];
    const ipc = this.video.split('&')[0].split('-')[1];
    const idBinomio = this.video.split('&')[0].split('-')[2];
    const fase = this.video.split('&')[0].split('-')[3].split('.')[0];
    const metadata = { idConcurso, ipc, idBinomio, fase };
    const { paymentIntent, error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:4242/public/checkout.html",//Solo para metodos de pago que requieran redireccionamiento
        payment_method_data: {
          billing_details: {
            address: {
              city: '',
              country: '',
              line1: '',
              line2: '',
              postal_code: '',
              state: ''
            },
            email: this.form.value.email,
            name: '',
            phone: ''
          },
          metadata
        },
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      this.showMessage(error.message);
      this.setLoading(false);
      return;
    } else if (error) {
      this.showMessage("An unexpected error occured.");
      this.setLoading(false);
      return;
    }

    const resp = await this.stripeService.releaseVideo(this.link, idConcurso, ipc, idBinomio, fase, this.form.value.release, this.form.value.email).toPromise();
    if(resp.error){
      $.NotificationApp.send("Error", resp.message, 'bottom-right', '#fa5c7c', 'error');
      this.setLoading(false);
      console.log(resp);
      return;
    }

    //Actualizar resultados en app
    if (this.form.value.release) this.stripeService.updateResults(ipc).toPromise();
    this.videoLiberado = true;
    this.setLoading(false);
  }

  // Fetches the payment intent status after payment submission
  public async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        this.showMessage("Payment succeeded!");
        break;
      case "processing":
        this.showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        this.showMessage("Your payment was not successful, please try again.");
        break;
      default:
        this.showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------
  public showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(() => {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }

  // Show a spinner on payment submission
  public setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      const submit: any = document.querySelector("#submit");
      submit.disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
      this.form.disable();
    } else {
      const submit: any = document.querySelector("#submit");
      submit.disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
      this.form.enable();
    }
  }

  public abrirVideoShowPass(){
    const idConcurso = this.video.split('&')[0].split('-')[0];
    console.log(idConcurso);
    this.router.navigate(['/video-show-pass', idConcurso]);
    return;
  }
}
