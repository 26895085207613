// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe: {
    baseUri: 'https://api.stripe.com/v1/',
    publishableKey: 'pk_live_TWoiGgZLPptcjqdqcyA5CezG'
  },
  firebase: {
    apiKey: 'AIzaSyBFhMU1VwH3l1Hf4yGoQE6Qm3SEY9fIO0Y',
    authDomain: 'ecuestredigital.firebaseapp.com',
    databaseURL: 'https://ecuestredigital.firebaseio.com',
    projectId: 'ecuestredigital',
    storageBucket: 'ecuestredigital',
    messagingSenderId: '581815221685'
  }
};

/*
pk_test_HCDB9zFmxkQut8e9xXpXV0Gm
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
